import { createApp } from 'vue'
import App from './App.vue'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'  // Import the FormKit default theme
import './custom-formkit-theme.css'  // Import the custom theme overrides
import { el } from '@formkit/i18n'  // Import the Greek locale
import { generateClasses } from '@formkit/themes'  // Import generateClasses

// Extend Greek localization with custom messages
const customEl = {
  ...el,
  validation: {
    ...el.validation,
    required: "Υποχρεωτικό πεδίο."
  }
};

// Configure FormKit with the extended Greek locale
const config = defaultConfig({
  locales: { el: customEl },
  locale: 'el',
  config: {
    classes: {
      ...generateClasses({
        global: {
          outer: 'mb-5'
        }
      })
    }
  }
});

const app = createApp(App)
app.use(plugin, config)
app.mount('#app')
