<template>
  <div id="app">
    <FormComponent />
  </div>
</template>

<script>
import FormComponent from './components/form.vue'

export default {
  name: 'App',
  components: {
    FormComponent
  }
}
</script>

<style>
/* Add global styles here */
</style>
