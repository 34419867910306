// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCJbXH6wF49AdNhftO6C4zAPJfTAZ14LtE",
  authDomain: "actform-df665.firebaseapp.com",
  databaseURL: "https://actform-df665-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "actform-df665",
  storageBucket: "actform-df665.appspot.com",
  messagingSenderId: "452526030885",
  appId: "1:452526030885:web:19798dcf8560663eeb3fcc",
  measurementId: "G-LMH1EGMKFR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };