<template>
  <div>
    <!-- Header with Logo -->
    <header class="header">
      <a href="https://www.actserv.com.cy" target="_blank" class="logo-link">
        <img src="../assets/ACTserv_logo.png" alt="ACTServ Logo" class="logo light-mode-logo" />
        <img src="../assets/ACTserv_logo_dark.png" alt="ACTServ Logo" class="logo dark-mode-logo" />
      </a>
      <div class="mode-toggle" @click="modeToggle" :class="darkDark">
        <div class="toggle">
          <div id="dark-mode"></div>
        </div>
        <span class="toggle-label">{{ darkMode ? 'Λειτουργία Ημέρας' : 'Λειτουργία Νύχτας' }}</span>
      </div>
    </header>

    <FormKit
      type="form"
      form-class="outer-container"
      @submit="submitForm"
      :config="{
        validationVisibility: 'dirty'
      }"
    >
      <h1>Προσφορά για λογιστικές υπηρεσίες</h1>

      <div class="form-grid">
        <!-- Question 1: Sales Invoices -->
        <FormKit
          type="radio"
          label="Αριθμός τιμολογίων πωλήσεων ανά μήνα"
          name="sales_invoices"
          v-model="formData.sales_invoices"
          :options="['0-20', '21-50', '51-80', '>80']"
          validation="required"
        ></FormKit>

        <!-- Conditional field based on previous answer -->
        <FormKit
          v-if="formData.sales_invoices === '>80'"
          type="number"
          label="Παρακαλούμε σημειώστε τον αριθμό"
          name="sales_invoices_count"
          v-model="formData.sales_invoices_count"
          validation="required|number"
        ></FormKit>

        <!-- Question 2: Purchase Invoices -->
        <FormKit
          type="radio"
          label="Αριθμός τιμολογίων αγορών και εξόδων ανά μήνα;"
          name="purchase_invoices"
          v-model="formData.purchase_invoices"
          :options="['0-20', '21-50', '51-80', '>80']"
          validation="required"
        ></FormKit>

        <!-- Conditional field based on previous answer -->
        <FormKit
          v-if="formData.purchase_invoices === '>80'"
          type="number"
          label="Παρακαλούμε σημειώστε τον αριθμό"
          name="purchase_invoices_count"
          v-model="formData.purchase_invoices_count"
          validation="required|number"
        ></FormKit>

        <!-- Question 3: Number of Bank Accounts -->
        <FormKit
          type="number"
          label="Αριθμός τραπεζικών λογαριασμών που κατέχει η Εταιρεία"
          name="bank_accounts"
          v-model="formData.bank_accounts"
          validation="required|number"
        ></FormKit>

        <!-- Question 4: Number of Bank Statement Pages -->
        <FormKit
          type="number"
          label="Συνολικός αριθμός σελίδων τραπεζικών λογαριασμών ανά μήνα (κίνηση τράπεζας)"
          name="bank_statements"
          v-model="formData.bank_statements"
          validation="required|number"
        ></FormKit>

        <!-- Question 5: Number of Employees -->
        <FormKit
          type="number"
          label="Αριθμός ατόμων που εργοδοτούνται από την Εταιρεία σας"
          name="employees"
          v-model="formData.employees"
          validation="required|number"
        ></FormKit>

        <!-- Question 6: Number of Creditors -->
        <FormKit
          type="number"
          label="Αριθμός πιστωτών"
          name="creditors"
          v-model="formData.creditors"
          validation="required|number"
        ></FormKit>

        <!-- Question 7: Number of Clients -->
        <FormKit
          type="number"
          label="Αριθμός πελατών"
          name="clients"
          v-model="formData.clients"
          validation="required|number"
        ></FormKit>

        <!-- Question 8: Rent Premises -->
        <FormKit
          type="radio"
          label="Η εταιρεία ενοικιάζει υποστατικό;"
          name="rent_premises"
          v-model="formData.rent_premises"
          :options="['Ναι', 'Όχι']"
          validation="required"
        ></FormKit>

        <!-- Question 9: Interested in Audit Services -->
        <FormKit
          type="radio"
          label="Θα θέλατε προσφορά για ελεγκτικές υπηρεσίες;"
          name="audit_services"
          v-model="formData.audit_services"
          :options="['Ναι', 'Όχι']"
          validation="required"
        ></FormKit>

        <!-- Question 10: Interested in Payroll Services -->
        <FormKit
          type="radio"
          label="Θα θέλατε προσφορά για υπηρεσίες μισθοδοσίας (Payroll);"
          name="payroll_services"
          v-model="formData.payroll_services"
          :options="['Ναι', 'Όχι']"
          validation="required"
        >
          <template #help>
            <div style="color: gray; font-size: smaller;">
              Οι υπηρεσίες μισθοδοσίας περιλαμβάνουν:
              <ul>
                <li>Οργάνωση και τήρηση των λογιστικών βιβλίων της Εταιρείας/Αυτοεργοδοτούμενου</li>
                <li>Ετοιμασία Λογαριασμών Διαχείρισης</li>
                <li>Εγγραφή/διαγραφή υπαλλήλων ή αυτοεργοδοτούμενων στις υπηρεσίες κοινωνικών ασφαλίσεων Κύπρου</li>
                <li>Ετοιμασία και πληρωμή κοινωνικών ασφαλίσεων και άλλων εισφορών</li>
                <li>Ετοιμασία και υποβολή Δήλωσης Εργοδότη (Ε. Πρ.7)</li>
                <li>Προετοιμασία και διανομή των ετήσιων εντύπων μισθοδοσίας (IR63) στους υπαλλήλους</li>
              </ul>
            </div>
          </template>
        </FormKit>

        <!-- Contact Information -->
        <FormKit
          type="email"
          label="Email επικοινωνίας"
          name="email"
          v-model="formData.email"
          placeholder="example@example.com"
          validation="required|email"
        ></FormKit>

        <FormKit
          type="tel"
          label="Τηλέφωνο επικοινωνίας(προαιρετικό)"
          name="phone"
          v-model="formData.phone"
          placeholder="00357XXXXXX"
        ></FormKit>

        <FormKit
          type="text"
          label="Όνομα Εταιρείας (προαιρετικό)"
          name="company"
          v-model="formData.company" >
      </FormKit>

       <!-- New Comments Field -->
       <FormKit
          type="textarea"
          label="Σχόλια (προαιρετικό)"
          name="comments"
          v-model="formData.comments"
          placeholder="Προσθέστε τυχόν σχόλια εδώ"
        ></FormKit>
      
         <!-- reCAPTCHA -->
      <div id="g-recaptcha" class="g-recaptcha"></div>
      </div>

    </FormKit>

    <!-- Popup message -->
    <div v-if="showPopup" class="popup-overlay" @click="closePopup">
      <div class="popup-message" @click.stop>
        <p>Σας ευχαριστούμε για την υποβολή! Θα σας απαντήσουμε σύντομα.</p>
        <button @click="closePopup">Κλείσιμο</button>
      </div>
    </div>

    <!-- Footer -->
    <footer class="footer">
      © Copyright 2024 | ACTServ Limited | All Rights Reserved | 
      <a href="https://www.actserv.com.cy/privacy" target="_blank">Privacy Policy</a>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { database } from '../firebase';
import { ref as dbRef, set } from 'firebase/database';

export default {
  name: 'FormComponent',
  data() {
    return {
      formData: {
        sales_invoices: '',
        sales_invoices_count: '',
        purchase_invoices: '',
        purchase_invoices_count: '',
        bank_accounts: '',
        bank_statements: '',
        employees: '',
        creditors: '',
        clients: '',
        rent_premises: '',
        audit_services: '',
        payroll_services: '',
        email: '',
        phone: '',
        company: '',
        comments: '' // New comments field
      },
      recaptchaToken: '',
      showPopup: false,
      darkMode: false,
      isSubmitting: false // New state to track form submission
    };
  },
  methods: {
    async submitForm() {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;

      // Execute reCAPTCHA v3 and get the token
      window.grecaptcha.execute('6LdsTgQqAAAAAKAKx-b3caPiU5yJh2u5dCbnnPD6', { action: 'submit' }).then(async (token) => {
        this.recaptchaToken = token;

        try {
          const response = await axios.post('https://europe-west1-actform-df665.cloudfunctions.net/verifyRecaptcha', {
            token: this.recaptchaToken
          });

          if (!response.data.success) {
            alert('Failed to verify reCAPTCHA.');
            this.isSubmitting = false;
            return;
          }

          const sanitizedEmail = this.sanitizeEmail(this.formData.email);
          const userRef = dbRef(database, 'users/' + sanitizedEmail);

          // Save form data to Firebase
          await set(userRef, this.formData);

          // Send email notification
          await axios.post('https://europe-west1-actform-df665.cloudfunctions.net/sendEmail', this.formData);

          // Show popup message
          this.showPopup = true;
        } catch (error) {
          console.error('Error writing to database:', error);

          if (error.response) {
            // Server responded with a status other than 200 range
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            // Request was made but no response received
            console.error('Request data:', error.request);
          } else {
            // Something happened in setting up the request
            console.error('Error message:', error.message);
          }

          console.error('Axios config:', error.config);
          alert('Σφάλμα κατά την υποβολή της φόρμας. Παρακαλούμε προσπαθήστε ξανά.');
        } finally {
          this.isSubmitting = false;
        }
      });
    },
    sanitizeEmail(email) {
      return email.replace(/[.#$/[\]]/g, '_');
    },
    closePopup() {
      this.showPopup = false;
      window.location.href = 'https://www.actserv.com.cy';
    },
    dark() {
      document.querySelector('body').classList.add('dark-mode');
      this.darkMode = true;
      this.$emit('dark');
      this.updateLogo('dark');
    },
    light() {
      document.querySelector('body').classList.remove('dark-mode');
      this.darkMode = false;
      this.$emit('light');
      this.updateLogo('light');
    },
    modeToggle() {
      this.darkMode = !this.darkMode;
      if (this.darkMode) {
        this.dark();
      } else {
        this.light();
      }
      localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    },
    updateLogo(theme) {
      const lightLogo = document.querySelector('.light-mode-logo');
      const darkLogo = document.querySelector('.dark-mode-logo');
      if (theme === 'dark') {
        lightLogo.style.display = 'none';
        darkLogo.style.display = 'block';
      } else {
        lightLogo.style.display = 'block';
        darkLogo.style.display = 'none';
      }
    },
    updateLogoBasedOnColorScheme() {
      const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      const savedTheme = localStorage.getItem('theme');
      const currentTheme = savedTheme || (isDarkMode ? 'dark' : 'light');
      this.darkMode = currentTheme === 'dark';
      if (this.darkMode) {
        this.dark();
      } else {
        this.light();
      }
    }
  },
  mounted() {
    // Load reCAPTCHA v3 script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LdsTgQqAAAAAKAKx-b3caPiU5yJh2u5dCbnnPD6';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    // Log image load errors
    const lightLogo = document.querySelector('.light-mode-logo');
    const darkLogo = document.querySelector('.dark-mode-logo');

    lightLogo.onerror = () => console.error("Failed to load light-mode logo");
    darkLogo.onerror = () => console.error("Failed to load dark-mode logo");

    lightLogo.onload = () => console.log("Light-mode logo loaded successfully");
    darkLogo.onload = () => console.log("Dark-mode logo loaded successfully");

    // Initialize theme
    this.updateLogoBasedOnColorScheme();

    // Listen for changes in color scheme
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.updateLogoBasedOnColorScheme);
  }
};
</script>

<style>

:root {
  --dark: #171717;
  --mode-toggle-bg: #262626;
  --input-bg-dark: #333333;
  --input-text-dark: #ffffff;
}

/* Existing Styles */
.outer-container {
  width: 90%;
  max-width: 900px;
  border: 1px solid #e4e4e4;
  padding: 1.5em;
  border-radius: 1em;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1em auto;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

h1 {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
}

.mode-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  background-color: var(--mode-toggle-bg);
  border-radius: 24px;
  transition: background-color 0.5s ease;
}

.mode-toggle .toggle {
  position: relative;
  width: 44px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 24px;
  transition: background-color 0.5s ease;
}

.mode-toggle .toggle div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a5abba;
  top: 2px;
  left: 2px;
  transition: transform 0.5s ease;
}

.mode-toggle .toggle-label {
  margin-left: 10px;
  color: #ffffff;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

body.dark-mode .mode-toggle .toggle div {
  transform: translateX(20px);
}

body.dark-mode .mode-toggle {
  background-color: lighten(var(--mode-toggle-bg), 5%);
}

.light-mode-logo {
  display: block;
}

.dark-mode-logo {
  display: none;
}

body.dark-mode .light-mode-logo {
  display: none;
}

body.dark-mode .dark-mode-logo {
  display: block;
}

body {
  background-color: #fff;
  color: var(--dark);
  transition: background-color 0.2s ease, color 0.2s ease;
}

body.dark-mode {
  background-color: var(--mode-toggle-bg);
  color: #ffffff;
}

body.dark-mode input,
body.dark-mode textarea,
body.dark-mode select {
  background-color: var(--input-bg-dark);
  color: var(--input-text-dark);
  border: 1px solid #444444;
}

body.dark-mode input::placeholder,
body.dark-mode textarea::placeholder {
  color: #bbbbbb;
}

/* Existing footer styles */
.footer {
  background-color: #61388D;
  color: white;
  text-align: center;
  padding: 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

.footer a {
  color: white;
  text-decoration: underline;
  margin-left: 5px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-message {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-message button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #61388D;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.popup-message button:hover {
  background-color: #4b2c7a;
}


</style>
